import {
  Autocomplete,
  Badge,
  Button,
  Checkbox,
  IconButton,
  TextField,
  Stack,
  Collapse,
  Box,
  List,
  ListItem
} from "@mui/material";
import React, { useState, useCallback, useMemo } from "react";
import { i18n } from "../util/i18n";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;

const CustomExpandMoreIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 10L12 16L18 10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

const CheckedIcon = () => (
    <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckBoxIcon">
      <rect x="4" y="4" width="16" height="16" fill="#304567"></rect>
      <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill="white"></path>
    </svg>
);

/**
 * This converts the project filter attributes into a format that can be used with our Autocomplete.
 * It will return a flat list, where hierarchical filters get a "group=true" property, and the value is the string concatenation of all child ids.
 * For all non-hierarchical filters, the value is the id of the of the attribute.
 */
const convertToOptionsList = (attributes, attributeId) => {
  let options = [];
  let topLevelOptions = attributes
      .filter((item) => item.id == attributeId)
      .map((attribute) => attribute.sorted_values)[0];

  console.log("Top Level Options", topLevelOptions);

  topLevelOptions?.map((topLevelOption) => {
    let option = {
      id: topLevelOption["id"],
      label: topLevelOption["name"],
      value: topLevelOption["id"],
    };

    // push the top level option
    options.push(option);

    let childAttributes = attributes.find(
        (item) => item.parent_value_id == option.id
    )?.sorted_values;
    // do we have child attributes?
    if (childAttributes) {
      option.value = ""; // For groups, value should only include ids of children
      option.group = true;
      option.children = []; // Store children reference

      childAttributes.map((childAttribute) => {
        const childOption = {
          id: childAttribute["id"],
          label: childAttribute["name"],
          value: childAttribute["id"],
          parent: option.id,
        };

        // Add child to parent's children array
        option.children.push(childOption);

        // append to parent option value
        option.value = option.value
            ? `${option.value},${childAttribute["id"]}`
            : childAttribute["id"];

        // We don't push children to the main options array anymore
        // as we'll render them nested
      });
    }
  });

  console.log("All available options", options);
  return options;
};

/**
 * Finds the Options within the options list that match the current filter value.
 * The selectedIdValues are a string of comma-separated ids.
 */
const getSelectedOptions = (allOptions, selectedIdValues) => {
  if (!selectedIdValues) {
    return [];
  }
  let selectedOptions = [];
  console.log("---", typeof selectedIdValues);

  const selectedIds = selectedIdValues.split(",");

  // First get top-level options that are selected
  allOptions.forEach(option => {
    if (selectedIds.includes(option.id)) {
      selectedOptions.push(option);
    }

    // Handle child options if they exist
    if (option.children) {
      option.children.forEach(child => {
        if (selectedIds.includes(child.id)) {
          selectedOptions.push(child);
        }
      });
    }
  });

  console.log("selected", selectedOptions);
  return selectedOptions;
};

// Find all children for a given parent ID
const findChildrenForParent = (options, parentId) => {
  const parent = options.find(option => option.id === parentId);
  return parent?.children || [];
};

const AttributeFilterAutocomplete = ({
                                       filtersParams,
                                       setFiltersParams,
                                       attributes,
                                       filterAttributeId,
                                       label = "Filter",
                                       placeholder,
                                       ...props
                                     }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // Track which parent items are expanded
  const [expandedParents, setExpandedParents] = useState({});

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  // Toggle expansion of a parent item
  const toggleExpanded = (parentId, event) => {
    event.stopPropagation(); // Prevent triggering selection
    setExpandedParents(prev => ({
      ...prev,
      [parentId]: !prev[parentId]
    }));
  };

  const dropdownIcon = (
      <div
          onClick={(e) => {
            e.stopPropagation();
            setDropdownOpen(!dropdownOpen);
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%'
          }}
      >
        {dropdownOpen ? <CloseIcon /> : <CustomExpandMoreIcon />}
      </div>
  );

  if (!attributes) {
    return <div>Project Attributes are required</div>;
  }

  if (!filterAttributeId) {
    return <div>Filter attribute ID is required</div>;
  }

  const filterId = filterAttributeId;
  const multiple = true;

  // Convert the attributes for this filter into a hierarchical list of options
  const options = convertToOptionsList(attributes, filterId);

  // Now check which of these options are currently selected
  const selectedValue = filtersParams["f"] ? String(filtersParams["f"][`${filterId}`]) : "";
  let selectedOptions = getSelectedOptions(options, selectedValue);

  // Collect all option IDs for flat filtering
  const getAllOptionsFlat = (opts) => {
    let flat = [];
    opts.forEach(opt => {
      flat.push(opt);
      if (opt.children) {
        opt.children.forEach(child => flat.push(child));
      }
    });
    return flat;
  };

  const allOptionsFlat = getAllOptionsFlat(options);

  const handleChanged = (event, value) => {
    console.log("Value changed", value);
    // Make sure we have an array
    const valueArray = Array.isArray(value) ? value : [value];

    let filterAttributeList = valueArray.map((o) => o.id); // Use ID directly
    console.log("filterAttributeList", filterAttributeList);

    // Ensure no duplicates
    filterAttributeList = [...new Set(filterAttributeList)];

    let newFilterParams = {
      ...filtersParams,
      f: {
        ...filtersParams.f,
        [`${filterId}`]: filterAttributeList
      },
      offset: 0,
    };

    if (filterAttributeList.length === 0) {
      delete newFilterParams.f[`${filterId}`];
    }

    setFiltersParams(newFilterParams);
  };

  // Select all options
  const handleSelectAll = useCallback(() => {
    const allIds = allOptionsFlat.map(option => option.id);
    setFiltersParams((prevFiltersParams) => ({
      ...prevFiltersParams,
      f: {
        ...prevFiltersParams.f,
        [`${filterId}`]: allIds,
      },
    }));
  }, [allOptionsFlat, filterId, setFiltersParams]);

  // Reset all selections
  const handleResetAll = useCallback(() => {
    setFiltersParams((prevFiltersParams) => {
      let result = {
        ...prevFiltersParams,
        f: {
          ...prevFiltersParams.f,
        },
      };
      delete result.f[`${filterId}`];
      return result;
    });
  }, [filterId, setFiltersParams]);

  // Custom rendering for the dropdown
  const renderOptionContent = (props, option, state) => {
    // This is the special action item
    if (option.SelectAll) {
      const allSelected = allOptionsFlat.length === selectedOptions.length;
      const noneSelected = selectedOptions.length === 0;

      return (
          <li {...props} style={{ flexDirection: 'column', alignItems: 'start', justifyContent: 'space-between' }}>
            <Button
                variant="text"
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  handleSelectAll();
                }}
                disabled={allSelected}
            >
              Alle auswählen
            </Button>
            <Button
                variant="text"
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  handleResetAll();
                }}
                disabled={noneSelected}
            >
              Filter zurücksetzen
            </Button>
          </li>
      );
    }

    // This is a parent option
    if (option.group && option.children) {
      const isExpanded = expandedParents[option.id];
      const hasSelectedChildren = option.children.some(child =>
          selectedOptions.some(selected => selected.id === child.id)
      );

      // Function to handle parent checkbox click
      const handleParentCheckboxClick = (e) => {
        e.stopPropagation();

        // If parent is selected, deselect parent and all children
        if (state.selected) {
          const childIds = option.children.map(child => child.id);
          const newSelected = selectedOptions.filter(
              opt => opt.id !== option.id && !childIds.includes(opt.id)
          );
          handleChanged(e, newSelected);
        }
        // If parent is not selected, select parent and all children
        else {
          const childOptions = option.children.map(child =>
              allOptionsFlat.find(opt => opt.id === child.id)
          ).filter(Boolean);

          const newSelected = [
            ...selectedOptions.filter(opt => opt.id !== option.id && !option.children.map(c => c.id).includes(opt.id)),
            option,
            ...childOptions
          ];

          handleChanged(e, newSelected);
        }
      };

      return (
          <React.Fragment>
            <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                  icon={icon}
                  checkedIcon={<CheckedIcon />}
                  sx={{ marginRight: 1 }}
                  checked={state.selected || hasSelectedChildren}
                  onClick={handleParentCheckboxClick}
                  style={{ display: 'inline-flex' }} /* Ensuring the checkbox is visible */
              />
              <Box sx={{ flexGrow: 1, fontWeight: '600' }} onClick={(e) => handleParentCheckboxClick(e)}>
                {option.label}
              </Box>
              <IconButton
                  size="small"
                  onClick={(e) => toggleExpanded(option.id, e)}
                  sx={{
                    ml: 1,
                    bgcolor: '#2A3139',
                    padding: '4px',
                    borderRadius: '50%',
                    color: 'white',
                    '&:hover': {
                      bgcolor: '#2A3139',
                    }
                  }}
              >
                {isExpanded ? <CloseIcon fontSize="small" /> : <CustomExpandMoreIcon />}
              </IconButton>
            </li>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {option.children.map((child) => {
                  const isChildSelected = selectedOptions.some(selected => selected.id === child.id);

                  const handleChildCheckboxClick = (e) => {
                    e.stopPropagation();
                    // Find the child in allOptionsFlat
                    const childOption = allOptionsFlat.find(opt => opt.id === child.id);
                    if (childOption) {
                      const newSelected = isChildSelected
                          ? selectedOptions.filter(opt => opt.id !== child.id)
                          : [...selectedOptions, childOption];

                      handleChanged(e, newSelected);
                    }
                  };

                  return (
                      <ListItem
                          key={child.id}
                          onClick={handleChildCheckboxClick}
                          sx={{ paddingLeft: '32px !important', py: 0 }}
                      >
                        <Checkbox
                            icon={icon}
                            checkedIcon={<CheckedIcon />}
                            checked={isChildSelected}
                            onClick={handleChildCheckboxClick}
                        />
                        {child.label}
                      </ListItem>
                  );
                })}
              </List>
            </Collapse>
          </React.Fragment>
      );
    }

    // This is a regular option (not a parent with children)
    return (
        <li {...props}>
          <Checkbox
              icon={icon}
              checkedIcon={<CheckedIcon />}
              checked={state.selected}
              onClick={(e) => {
                e.stopPropagation();
                const newSelected = state.selected
                    ? selectedOptions.filter(opt => opt.id !== option.id)
                    : [...selectedOptions, option];
                handleChanged(e, newSelected);
              }}
          />
          {option.label}
        </li>
    );
  };

  return (
      <>
        <Autocomplete
            disablePortal={false}
            open={dropdownOpen}
            id={`attribute-filter-${filterId}`}
            fullWidth={true}
            limitTags={3}
            multiple={multiple}
            popupIcon={dropdownIcon}
            value={selectedOptions}
            onChange={handleChanged}
            options={[{ label: 'Actions', SelectAll: true }, ...options]} // Only parents in main options list
            onOpen={() => setDropdownOpen(true)}
            onClose={() => setDropdownOpen(false)}
            renderTags={(value, getTagProps, ownerState) => (
                <Badge sx={{ mx: 0 }} badgeContent={ownerState.value.length}></Badge>
            )}
            disableCloseOnSelect
            getOptionLabel={(option) => option?.label || ''}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option, state) => renderOptionContent(props, option, state)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={""}
                    placeholder={placeholder || label}
                    variant="standard"
                    onClick={() => setDropdownOpen(true)}
                />
            )}
            sx={{ '& .MuiAutocomplete-endAdornment': { top: 'auto' } }}
        />
      </>
  );
};

export default AttributeFilterAutocomplete;
