import { useQuery } from "react-query";

const attributesToFilters = (attributes) => {
    return attributes;
};

const loadFilters = (baseUrl, language = "de") => {
    return useQuery(["attributes"], () => {
        return fetch(`${baseUrl}/attributes?lang=${language}&returnValuesAsArray=1`)
            .then((res) => {
                if (!res.ok) {
                    console.log("Error fetching Filter attributes from ", res.url);
                    return Promise.reject(res);
                }
                console.log("Fetched attributes from ", res.url);
                return res.json();
            })
            .then((data) => {
                let filters = attributesToFilters(data);
                console.log("loaded filters", filters);
                return filters;
            });
    },
    {
        refetchOnWindowFocus: false
    });
};

const loadJobs = (jobListFetchUrl, setTotalJobs) => {
  return useQuery(["joblist", jobListFetchUrl], async () => {
    try {
      const response = await fetch(jobListFetchUrl);
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      setTotalJobs(data.total); // Set total jobs count
      return { jobs: data.jobs };
    } catch (error) {
      console.error("Error fetching jobs:", error.message);
      throw error;
    }
  });
};

export { loadFilters, loadJobs };