import * as React from "react";
import Pagination from "@mui/material/Pagination";
import { i18n } from "../util/i18n";
import { Skeleton } from "@mui/material";
import PropTypes from "prop-types";

const JobListPagination = ({
  className,
  filterParams,
  setFilterParams,
  setTriggerSubmit,
  totalJobs,
  loading = false,
  ...props
}) => {
  const currentPage = Math.floor(filterParams?.offset / filterParams?.limit) + 1;
  const totalPages = Math.ceil(totalJobs / filterParams?.limit);

  const handleOnChange = (event, page) => {
    setFilterParams({
      ...filterParams,
      offset: (page - 1) * filterParams.limit,
    });
    setTriggerSubmit(true);
    document.getElementById('root').scrollIntoView();
  };

  if (loading) {
    return (
      <Skeleton animation="wave" sx={{ bgcolor: "gray.300" }}>
        <Pagination />
      </Skeleton>
    );
  }

  if (totalPages <= 1) {
    return null;
  }

  return (
    <Pagination
      page={currentPage}
      count={totalPages}
      onChange={handleOnChange}
      variant="outlined"
      {...props}
    />
  );
};

JobListPagination.propTypes = {
  filterParams: PropTypes.object.isRequired,
  setFilterParams: PropTypes.func.isRequired,
  setTriggerSubmit: PropTypes.func.isRequired,
  totalJobs: PropTypes.number.isRequired,
  loading: PropTypes.bool,
};

export default JobListPagination;