import React, { useState, useEffect } from "react";
import { i18n } from "./util/i18n";
import { loadFilters, loadJobs } from "./util/JobQueryService";
import { useLocalStorage } from "react-use";
import LocalStorageContext from "./util/LocalStorageContext";
import FilterSection from "./components/FilterSection";
import JobList from "./components/JobList";
import NoJobsFound from "./components/NoJobsFound";
import JobListPagination from "./components/JobListPagination";
import JobAboSubscriptionModal from "./components/JobAboSubscriptionModal";
import {
  Grid,
  LinearProgress,
  Skeleton,
} from "@mui/material";
import FavouriteCounterButton from "./components/FavouriteCounterButton";
import settings from "./settings";
import { useLocation } from "react-router-dom";

const JOBS_PAGE_SIZE = settings.jobsPageSize || 5;

function fromUrlSearchParams(location, attributes) {
  let searchParams = new URLSearchParams(location.hash.slice(2) || location.search);
  
  let resultFilterParams = {
    offset: parseInt(searchParams.get('offset')) || 0,
    limit: parseInt(searchParams.get('limit')) || JOBS_PAGE_SIZE,
    workload: searchParams.get('workload'),
    q: searchParams.get('q'),
    lang: searchParams.get('lang') || 'de',
    f: {}
  };
  
  searchParams.forEach((value, key) => {
    if (key.startsWith('f:')) {
      const filterKey = key.slice(2);
      resultFilterParams.f[filterKey] = value.split(',');
    }
  });
  
  return resultFilterParams;
}

function App() {
  const baseUrl = settings.baseUrl;
  const language = i18n.lang;
  const FILTER = settings.filterIds;
  const [totalJobs, setTotalJobs] = useState(0);
  const location = useLocation();
  const [filterParams, setFilterParams] = useState(fromUrlSearchParams(location, null));
  const [jobListFetchUrl, setJobListFetchUrl] = useState(null);
  const [triggerSubmit, setTriggerSubmit] = useState(false);
  const [localStorageFavourites, setLocalStorageFavourites] = useLocalStorage(
    settings.localStorageKeyFavourites,
    {}
  );

  const createFetchUrl = (baseUrl, params) => {
    const mergeValuesWithSameKey = (params) => {
      const result = {};
      for (const [key, value] of Object.entries(params)) {
        const mainKey = key.split("_")[0];
        if (!result[mainKey]) {
          result[mainKey] = value;
        } else {
          result[mainKey] = `${result[mainKey]},${value}`;
        }
      }
      return result;
    };

    var url = `${baseUrl}/jobs?` +
      Object.keys(params)
        .filter((key) => params[key] || key === "offset")
        .map((key) => {
          if (key === "f") {
            const mergedParams = mergeValuesWithSameKey(params.f);
            return Object.entries(mergedParams)
              .map(([key, value]) => `f=${key}:${value}`)
              .join("&");
          } else {
            return `${key}=${params[key]}`;
          }
        })
        .join("&");

    return url;
  };

  useEffect(() => {
    let url = createFetchUrl(baseUrl, filterParams);
    setJobListFetchUrl(url);
  }, [filterParams, baseUrl]);

  const { isLoading, isError, data } = loadJobs(jobListFetchUrl, setTotalJobs);

  const {
    isLoading: isLoadingFilters,
    isError: isErrorFilters,
    data: currentFilters,
  } = loadFilters(baseUrl, language);

  useEffect(() => {
    if (currentFilters?.attributes) {
      setFilterParams(fromUrlSearchParams(location, currentFilters.attributes));
    }
  }, [currentFilters, location]);

  function convertURL(url) {
    const pattern = /(\?f:[^&]*=.*)/;
    if (!url.includes('#') && pattern.test(url)) {
      return url.replace(pattern, '#/$1');
    }
    return url;
  }

  useEffect(() => {
    const currentURL = window.location.href;
    const newURL = convertURL(currentURL);
    if (newURL !== currentURL) {
      window.history.pushState({ path: newURL }, '', newURL);
      window.location.reload();
    }
  }, []);

  return (
    <LocalStorageContext.Provider value={[localStorageFavourites, setLocalStorageFavourites]}>
      <Grid container className="wrapper">
        {isLoadingFilters && <LinearProgress />}
        {isErrorFilters && <p>Error loading filters: {isErrorFilters}</p>}
        {currentFilters && (
          <>
            <FilterSection
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              setTriggerSubmit={setTriggerSubmit}
              attributes={currentFilters.attributes}
            />
            <Grid container justifyContent="space-between" marginBottom={8}>
              <Grid item>
                <JobAboSubscriptionModal
                  filterParams={filterParams}
                  setFilterParams={setFilterParams}
                  attributes={currentFilters.attributes}
                />
              </Grid>
              {settings.enableFavourites && (
                <Grid item xs={2} sm={2} container justifyContent="end">
                  <FavouriteCounterButton loading={!data} />
                </Grid>
              )}
            </Grid>
          </>
        )}

        <div className="jobsList">
          {isLoading && <LinearProgress />}
          {isError && <p>Error loading jobs: {isError}</p>}


          {data ? (
            data.jobs && data.jobs.length > 0 ? (
              <>
                <JobList
                  jobs={data.jobs}
                  subtitleAttribute={FILTER["Subtitel"]}
                  pensumAttribute={FILTER["Pensum"]}
                  klinikAttribute={FILTER["Klinik_Institut"]}
                  favourites={settings.enableFavourites ? "toggle" : "none"}
                />
                {totalJobs > JOBS_PAGE_SIZE && (
                  <JobListPagination
                    filterParams={filterParams}
                    setFilterParams={setFilterParams}
                    setTriggerSubmit={setTriggerSubmit}
                    totalJobs={totalJobs}
                  />
                )}
              </>
            ) : (
              <NoJobsFound submitted={triggerSubmit} />
            )
          ) : (
            <Skeleton
              sx={{ bgcolor: "white" }}
              variant="rectangular"
              width={"100%"}
              height={"500px"}
            />
          )}
        </div>
      </Grid>
    </LocalStorageContext.Provider>

  );
}

export default App;