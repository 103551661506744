import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect, useRef } from "react";
import { useDebounce } from "ahooks";

// import our utility functions for working with filters and languages
import { i18n } from "../util/i18n";

import ResetFiltersButton from "./ResetFiltersButton";
import TextSearchFilterInput from "./TextSearchFilterInput";
import WorkloadFilterSlider from "./WorkloadFilterSlider";
import AttributeFilterAutocomplete from "./AttributeFilterAutocomplete";
// import JobAboSubscriptionModal from "./JobAboSubscriptionModal";
import AttributeFilterDropdown from "./AttributeFilterDropdown";

/**
 * A React component holding a set of filters. Reasonable default implementation.
 * It needs the filterParams state (and setter), and passes it to the child filter components.
 *
 * It supports multiple modes:
 * - disbaleHeader=true: Don't show the header with the search field
 * - disableButtons=true: Don't show the buttons to reset the filters or to subscribe to a job Abo
 * - DefaultExpanded=false: Don't show the Accordion expanded by default
 */

const FilterSection = ({
  filterParams,
  setFilterParams,
  setTriggerSubmit,
  disableHeader = false,
  disableButtons = false,
  disableWorkload = false,
  attributes = null,
  defaultExpanded = false,
  totalJobs,
  ...props
}) => {

  // Dependend filter for Berufsgruppe
  const [selectedParent, setSelectedParent] = useState(null);
  const debouncedParams = useDebounce(filterParams, {
    wait: 100,
  });
  const [internalParams, setInternalParams] = useState(filterParams);
  useEffect(() => {
    setInternalParams(filterParams);
  }, [debouncedParams]);

  return (
    <>

            <Grid
                className="search-wrapper"
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={4}
                marginBottom={8}
            >
              <Grid item xs={12} sm={12} md={9}>
                <TextSearchFilterInput
                    filterParams={filterParams}
                    setFilterParams={setFilterParams}
                    setTriggerSubmit={setTriggerSubmit}
                />
              </Grid>
            </Grid>

            <Grid
                className="dropdowns-wrapper"
                container
                direction="row"
                alignItems="flex-start"
                spacing={4}
                marginBottom={8}
            >
              <Grid item xs={12} md={3}>
                <AttributeFilterAutocomplete
                    filtersParams={filterParams}
                    setFiltersParams={setFilterParams}
                    setTriggerSubmit={setTriggerSubmit}
                    attributes={attributes}
                    filterAttributeId={70}
                    label={i18n.translations.filterBerufsgruppeLabel}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <AttributeFilterAutocomplete
                    filtersParams={filterParams}
                    setFiltersParams={setFilterParams}
                    setTriggerSubmit={setTriggerSubmit}
                    attributes={attributes}
                    filterAttributeId={80}
                    label={i18n.translations.filterInstitutLabel}
                />
              </Grid>

          </Grid>

          {!disableWorkload && (
              <Grid container marginBottom={8}>
                <Grid className="pensum-slider" item xs={12} md={9} sx={{paddingLeft:4.4,paddingRight:4.4}}>
                  <WorkloadFilterSlider
                      filterParams={filterParams}
                      setFilterParams={setFilterParams}
                      setTriggerSubmit={setTriggerSubmit}
                  />
                </Grid>
              </Grid>
          )}
    </>
  );
};

export default FilterSection;
